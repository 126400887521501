<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <v-label
      ><b>{{ label }}</b></v-label
    >
<!-- INICIO DE MODAL DE ADD PRODUCTOS PARA VISTA DE VISITAS - KAREN -->
    <s-select-list-supplies-new 
      :dialog="dialog"
      :PlID="PlID"
      @hideModal="hideModal"
      @recharge="recharge"
      @close="close"
      return-object
            
      @input="inputListS($event)"          
    ></s-select-list-supplies-new> <!--  -->
    <!-- FIN DE MODAL - KAREN  -->
 
    <s-select-generic
      :config="config"
      itemvalue="LsID"
      autocomplete
      clearable
      :text="text"
      ref="plagues"
      itemtext="LsDescription"
      :autofocus="autofocus"
      @add="showModal"
      :full="full"
      id="PlID"
      :value="value"
      add 
      @input="input($event)"
      :returnObject="returnObject"
      :disabled="disabled"
      
    />
  </div>
</template>
<script>
import _sWorkerService from "@/services/Technicalassistance/ListSuppliesService.js";
import SSelectListSuppliesNew from './SSelectListSuppliesNew.vue';

export default {
  name: "sSelectWorker",
  components: {SSelectListSuppliesNew},
  props: {
    text: {
      type: String,
      default: "",
    },
    value: null,
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: true,
    },
    PlID: {
      type: Number,
      default: 0,
    },
    TypeCultive: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  
  methods: {
    input(v) {
      this.$emit("input", v);
    },
    focus() {
      this.$refs.plagues.focus();
    },
    addEvent() { //nuevo agregado por Karen
      this.dialog = true;      
    },
    //MOSTRAR MODAL 
    showModal() {      
      this.dialog = true;        
    },
    // OCULTAR MODAL
    hideModal() {      
      this.dialog = false;      
    },
    // REFRESCAR MODAL
    recharge() {
      this.$refs.crud.refresh();
    },
    // CERRAR MODAL 
    close(){
      this.dialog=false;
    }, 
    /* inputListS(inputLSupplies,item){
      if(inputLSupplies){
        item.LsDescription=inputLSupplies.LsDescription;
       
      }
    }, */
    
    
  },
  watch: {
    PlID() {
      this.config.params.PlId = this.PlID;
    },
    TypeCultive() {
      this.config.params.TypeCultive= this.TypeCultive;
    },
  },
  data: () => ({
    item: 0,
    items: [],
    config: {},
    type: 0,
    dialog: false,
    
   

  }),
  created() {
    this.config = {
      url: _sWorkerService.GetUrlListByPlague(),
      params: { PlId: this.PlID, requestID: 42,TypeCultive:this.TypeCultive ,search:"",requestID:this.$fun.getUserID()},
    };
  },
};
</script>
