<template>
<div style="padding-top:-2px;margin-top:-2px;">
  <v-label ><b>{{label}}</b></v-label>
  <s-select-generic
    :config="config"
    itemvalue="PlID"
    autocomplete clearable
    :text="text" ref='plagues'
    itemtext="PlDescription" :autofocus=autofocus
    @add="addEvent()"
    id="PlID" full
    :value="value"
    @input="input($event)"
    :returnObject="returnObject"
    :disabled ="disabled"
  />
  </div>
</template>
<script>
import _sWorkerService from "@/services/Technicalassistance/TasPlagueService.js";
export default {
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
    value: null,
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    typepayrollallow: {
      type: Number,
      default: 0,
    },
     typeCultive: {
      type: Number,
      default: 0,
    },
    disabled:{
      type: Boolean,
      default:false,
    },
    autofocus:{
      type: Boolean,
      default:false,
    }
  },
  methods: {
    input(v) {
      this.$emit("input", v);
    },
    focus(){
        this.$refs.plagues.focus();
    },
  },
  watch: {
    typeCultive(){
      this.config.params.TypeCultive=this.typeCultive;
    }
  },
  data: () => ({
    item: 0,
    items: [],
    config: {},
    type: 0,
  }),
  created() {
    this.config = {
      url: _sWorkerService.getUrlList(),
      params: { TypeCultive:this.typeCultive },
    };    
  },
};
</script>
