<template>
    <div>
        <v-dialog v-model="dialog" width="700" persistent>  
            <v-card>
                 <v-col >
                <v-row>
                    <v-col>
                        <s-crud 
                        no-border
                        formPermanent
                        title="Nuevo Insumo"
                        :config="configInsumos"
                        @save="save($event)"
                        edit
                        add 
                        close 
                        @close="close()"
                        :filter="filter"
                        ref="crud"
                        :height="height"
                        @input="inputObject()"
                        v-if="dialog"
                        >  
                            <template scope="props">
                                <v-container class="">
                                    <v-row justify="center" class="">
                                        <v-col cols="12" class="s-col-form" xs="3" sm="3" md="3" >
                                            <s-text v-model="props.item.LsCode" label="Código (SAP)">
                                            </s-text>                 
                                        </v-col>
                                        <v-col class="s-col-form"  >
                                         <s-select-definition :def="1325" label="Tipo Insumo" v-model="props.item.TypeProduct"></s-select-definition>  
                                        </v-col>
                                        <v-col cols="12" class="s-col-form" xs="5" sm="5" md="5">
                                            <s-text  v-model="props.item.LsDescription" autofocus  label="Descripción" > </s-text>                        
                                        </v-col>
                                        
                                    </v-row>               
                                           <v-row>
                                            <v-col cols="12" class="s-col-form" lg="4">
                                            <s-select-definition v-model="props.item.LsUnitID" clearable id='DedValue' add :def="1206" label="Unidad">
                                            </s-select-definition>
                                        </v-col>
                                         <v-col  cols="12" class="s-col-form" xs="3" sm="3" md="3" lg="9" v-show="false">
                                                <s-select-definition disabled v-model="props.item.LsStatus" :def="1224" label="Estado">                                                  
                                                </s-select-definition>
                                            </v-col>
                                            <v-col cols="8" class="s-col-form" > 
                                              <s-text v-model=props.item.LsActiveIngredientName  label="Ingrediente Activo">                                                
                                              </s-text>
                                            </v-col>
        
                                        </v-row>
                                        <v-row justify="center">
                                            <v-col cols="12" class="s-col-form">
                                              <s-text v-model="props.item.LsObservation" label="Observaciones">
                                              </s-text> 
                                            </v-col>
                                        </v-row>               
                                </v-container>
                            </template>
                            <template v-slot:LsStatus="{ row }">
                                <v-chip style="margin:0px" x-small :color="row.LsStatus == 1 ? 'success' : 'error'">
                                    {{ row.LsStatus == 1 ? "Activo" : "Inactivo" }}
                                </v-chip>
                            </template> 
                            <template v-slot:LsStatusName="{ row }">
                                <v-chip style="margin:0px" x-small :color="row.LsStatus== 1 ? 'info' :row.LsStatus== 2 ?  'success':'error'">
                                <b> {{row.LsStatusName}}</b>
                                </v-chip>
                            </template>                        
                        </s-crud>
                    </v-col>
                </v-row>
                </v-col>                
            </v-card>
        </v-dialog>
    </div> 
</template>


<script>
import _sVarietyService from "@/services/Technicalassistance/ListSuppliesService.js";
import SSelectOriginSupplies from '../../../components/TecnicalAssistence/ListSupplies/SSelectOriginSupplies.vue';
import SSelectPlagueCultive from '../../../components/TecnicalAssistence/Plagues/SSelectPlagueCultive.vue';

export default {
  name: "RequirementServiceSupplies",
  components: {SSelectOriginSupplies,SSelectPlagueCultive  },
  props: {
          dialog: { type: Boolean, default: false }, 
          DedID: { type: Number, default: 0 },   
  },

  data() {
    return {
     /*  dialogEdit: false, */
     height:"10",
     
     };     
  },

  computed: {   
    configInsumos() {
      return {
        model: {
          
          LsID: "ID",
          LsDescription: "string",         
          DedDescription: "string", 
          LsStatus: "",
          LsStatusName: "status"
          //
        }, 
         service: _sVarietyService,
         headers: [],
      };
    }, 
  },  
  
  methods: {
    Initialize() {   
       this.filter = {DedID: this.DedID};
    },
    close(){
      this.$emit("close");
    },
    inputObject() {
      this.$emit("inputObject");
    },
    save(item) {
      item.TypeSupplies=1;
      this.inputObject = item.LsDescription;
      
        if (item.LsDescription.length == 0) {
          this.$fun.alert("Registre la descripción", "warning");
          return;
        }     
      
        if (item.LsUnitID == '' || item.LsUnitID == null) {
          this.$fun.alert("Seleccione la unidad de medida", "warning");
          return;
        }
      item.LsStatus =1;
      if(!item.LsCode) item.LsCode='';
        item.SecStatus=1;
        item.save();         
        this.$emit('close');
    },    
  },
  watch: {
    DedID(){           
      this.Initialize();    
    },
  },
    created() {
      this.filter = { DedID: 0 };
  },
};
</script>
