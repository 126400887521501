import Service from "../../Service";

const resource = "visit/";

export default {
    find(VstID, requestID) {
        return Service.post(resource + "find", {}, {
            params: {
                VstID: VstID,
                requestID: requestID,
            },
        });
    },
    save(parameters, requestID) {
        return Service.post(resource + "save", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID }
        });
    },
    getVisitFarmLot(parameters, requestID) {
        return Service.post(resource + "getVisitFarmLot", parameters, {
            params: { requestID }
        });
    },

    tecnicalreportpdf(parameters, requestID) {
        return Service.post(resource + "tecnicalreportpdf", parameters, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        });
    },

    graphvisit(parameters, requestID) {
        return Service.post(resource + "graphvisit", parameters, {
            params: { requestID }
        });
    },

    graphvisitevaluation(parameters, requestID) {
        return Service.post(resource + "graphvisitevaluation", parameters, {
            params: { requestID }
        });
    },

    reportAll(parameters, requestID) {
        return Service.post(resource + "reportAll", parameters, {
            params: { requestID }
        });
    },
};